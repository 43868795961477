@import "../../style/themes/default";
@import "../../style/mixins/index";

@avatar-prefix-cls: ~"@{ant-prefix}-avatar";

.@{avatar-prefix-cls} {
  .reset-component;
  display: inline-block;
  text-align: center;
  background: @avatar-bg;
  color: @avatar-color;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  vertical-align: middle;

  &-image {
    background: transparent;
  }

  .avatar-size(@avatar-size-base, @avatar-font-size-base);

  &-lg {
    .avatar-size(@avatar-size-lg, @avatar-font-size-lg);
  }

  &-sm {
    .avatar-size(@avatar-size-sm, @avatar-font-size-sm);
  }

  &-square {
    border-radius: @avatar-border-radius;
  }

  & > img {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.avatar-size(@size, @font-size) {
  width: @size;
  height: @size;
  line-height: @size;
  border-radius: 50%;

  & > * {
    line-height: @size;
  }

  &.@{avatar-prefix-cls}-icon {
    font-size: @font-size;
  }
}

@primary-color: #0f73b9;@primary: #0f73b9;@primaryDark: #09609c;@borderColor: #D9D9D9;@headerHeight: 75px;