@import "../../style/mixins/index";

.antCheckboxFn(@checkbox-prefix-cls: ~"@{ant-prefix}-checkbox") {
  @checkbox-inner-prefix-cls: ~"@{checkbox-prefix-cls}-inner";
  // 一般状态
  .@{checkbox-prefix-cls} {
    .reset-component;
    white-space: nowrap;
    cursor: pointer;
    outline: none;
    display: inline-block;
    line-height: 1;
    position: relative;
    vertical-align: middle;
    top: -0.09em;

    .@{checkbox-prefix-cls}-wrapper:hover &-inner,
    &:hover &-inner,
    &-input:focus + &-inner {
      border-color: @checkbox-color;
    }

    &-checked:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: @border-radius-sm;
      border: 1px solid @checkbox-color;
      content: '';
      animation: antCheckboxEffect 0.36s ease-in-out;
      animation-fill-mode: both;
      visibility: hidden;
    }

    &:hover:after,
    .@{checkbox-prefix-cls}-wrapper:hover &:after {
      visibility: visible;
    }

    &-inner {
      position: relative;
      top: 0;
      left: 0;
      display: block;
      width: @checkbox-size;
      height: @checkbox-size;
      border: @border-width-base @border-style-base @border-color-base;
      border-radius: @border-radius-sm;
      background-color: @checkbox-check-color;
      transition: all .3s;

      &:after {
        @check-width: (@checkbox-size / 14) * 5px;
        @check-height: (@checkbox-size / 14) * 8px;
        transform: rotate(45deg) scale(0);
        position: absolute;
        left: (@checkbox-size - @check-width) / 2 - 0.5px * (@checkbox-size / 14);
        top: (@checkbox-size - @check-height) / 2 - 2px * (@checkbox-size / 14);
        display: table;
        width: @check-width;
        height: @check-height;
        border: 2px solid @checkbox-check-color;
        border-top: 0;
        border-left: 0;
        content: ' ';
        transition: all .1s @ease-in-back, opacity .1s;
        opacity: 0;
      }
    }

    &-input {
      position: absolute;
      left: 0;
      z-index: 1;
      cursor: pointer;
      opacity: 0;
      top: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }

  // 半选状态
  .@{checkbox-prefix-cls}-indeterminate .@{checkbox-inner-prefix-cls}:after {
    @indeterminate-width: @checkbox-size - 8px;
    @indeterminate-height: @checkbox-size - 8px;
    content: ' ';
    transform: translate(-50%, -50%) scale(1);
    border: 0;
    left: 50%;
    top: 50%;
    width: @indeterminate-width;
    height: @indeterminate-height;
    background-color: @checkbox-color;
    opacity: 1;
  }

  .@{checkbox-prefix-cls}-indeterminate.@{checkbox-prefix-cls}-disabled .@{checkbox-inner-prefix-cls}:after {
    border-color: @disabled-color;
  }

  // 选中状态
  .@{checkbox-prefix-cls}-checked .@{checkbox-inner-prefix-cls}:after {
    transform: rotate(45deg) scale(1);
    position: absolute;
    display: table;
    border: 2px solid @checkbox-check-color;
    border-top: 0;
    border-left: 0;
    content: ' ';
    transition: all .2s @ease-out-back .1s;
    opacity: 1;
  }

  .@{checkbox-prefix-cls}-checked {
    .@{checkbox-inner-prefix-cls} {
      background-color: @checkbox-color;
      border-color: @checkbox-color;
    }
  }

  .@{checkbox-prefix-cls}-disabled {
    cursor: not-allowed;

    &.@{checkbox-prefix-cls}-checked {
      .@{checkbox-inner-prefix-cls}:after {
        animation-name: none;
        border-color: @disabled-color;
      }
    }

    .@{checkbox-prefix-cls}-input {
      cursor: not-allowed;
    }

    .@{checkbox-inner-prefix-cls} {
      border-color: @border-color-base !important;
      background-color: @input-disabled-bg;
      &:after {
        animation-name: none;
        border-color: @input-disabled-bg;
      }
    }

    & + span {
      color: @disabled-color;
      cursor: not-allowed;
    }
  }

  .@{checkbox-prefix-cls}-wrapper {
    .reset-component;
    line-height: unset;
    cursor: pointer;
    display: inline-block;
    & + & {
      margin-left: 8px;
    }
  }

  .@{checkbox-prefix-cls}-wrapper + span,
  .@{checkbox-prefix-cls} + span {
    padding-left: 8px;
    padding-right: 8px;
  }

  .@{checkbox-prefix-cls}-group {
    .reset-component;
    display: inline-block;
    &-item {
      display: inline-block;
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
    }
    &-item + &-item {
      margin-left: 0;
    }
  }
}

@keyframes antCheckboxEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
